
import { Services } from "@/core/models/metadata/services.ts";
import {
  ContactFormArea,
  contactFormAreaToServerEnum,
} from "@/core/models/queries/contactFormArea.enum.ts";
import {
  ContactFormQuery,
  PublicContactFormQuery,
} from "@/core/models/queries/contactFormQuery.ts";
import Resources from "@/core/models/resources.ts";
import { PublicContactFormService } from "@/core/services/publicContactFormService.ts";
import { PrivateContactFormService } from "@/core/services/privateContactFormService.ts";
import { useAuth0 } from "@auth0/auth0-vue";
import { inject } from "vue";
import { defineComponent } from "vue";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import RadialSpinner from "../shared/radialSpinner.vue";
import BlockableButton from "../shared/blockableButton.vue";
import { Checkbox } from "vue-recaptcha/head";
import { ContactFormResponse } from "@/core/models/responses/contactFormResponse.ts";
import LeftPanel from "../userProfile/leftPanel.vue";
import ErrorsManager from "../shared/errorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { ErrorsService } from "@/core/services/errorsService.ts";

let resources!: Resources;
let publicContactFormService!: PublicContactFormService;
let privateContactFormService!: PrivateContactFormService;
let validationRulesService!: ValidationRulesService;
let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
let errorsService!: ErrorsService;
export default defineComponent({
  name: "ContactForm",
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService
      .retrieveUrlAndTitleFromNotDefault()
      .then((response: RemoteOriginatingSiteResponse) => {
        this.buttonInfo = response;
        this.buttonInfoLoading = false;
      });
  },
  data() {
    const auth0 = useAuth0();
    resources = inject(Services.Resources) as Resources;
    publicContactFormService = inject(
      Services.PublicContactForm
    ) as PublicContactFormService;
    privateContactFormService = inject(
      Services.PrivateContactForm
    ) as PrivateContactFormService;
    const isAuthenticated = auth0.isAuthenticated.value;
    validationRulesService = inject(
      Services.ValidationRules
    ) as ValidationRulesService;
    errorsService = inject(Services.ErrorsService)!;
    return {
      buttonBack: resources.buttons.back,
      canEditFields: !isAuthenticated,
      lastUserName: isAuthenticated
        ? `${
            auth0.user.value.given_name
          } ${auth0.user.value.family_name}`
        : "",
      lastUserEmail: isAuthenticated ? auth0.user.value.email : "",
      buttonSend: resources.buttons.send,
      loading: false,
      contactCS: resources.messages.contactCS,
      contactFormArea: resources.messages.defaultAreaOptionDropdown,
      contactFormAreas: [
        ContactFormArea.Books,
        ContactFormArea.ChangeMakers,
        ContactFormArea.Donations,
        ContactFormArea.FaradayForum,
        ContactFormArea.Membership,
        ContactFormArea.VolunteerHub,
      ],
      contactFormDescription: resources.messages.contactFormDescription,
      userId: isAuthenticated ? auth0.user.value.sub : "",
      details: "",
      isValid: false,
      isAuthenticated: isAuthenticated,
      isAuthenticatedForLeftPanel: isAuthenticated && (auth0.user.value?.email_verified ?? false),
      defaultOptionDropdown: resources.messages.defaultOptionDropdown,
      rulesFirstName: isAuthenticated
        ? []
        : validationRulesService.getValidationRules().composedName,
      rulesEmail: isAuthenticated
        ? []
        : validationRulesService.getValidationRules().email,
      rulesDropdown: validationRulesService.getValidationRules().dropdown,
      rulesTextArea: validationRulesService.getValidationRules().textarea,
      message: resources.titles.message,
      buttonInfo,
      buttonInfoLoading: true,
      reCaptchaResponse: "",
      reCaptchaIsSuccessful: false,
      labels: {
        name: resources.labels.yourName,
        email: resources.labels.yourEmail,
        form: resources.labels.formDropdown,
        textArea: resources.labels.textArea,
      },
      errorTypes: [ErrorType.ContactForm, ErrorType.ReCaptcha],

    };
  },
  methods: {
    async submitForm() {
      if (!this.isValid) return;
      errorsService.deleteByType(ErrorType.ReCaptcha);
      if (!this.reCaptchaIsSuccessful && !this.isAuthenticated) {
        errorsService.push(resources.errors.verifyRecaptcha, ErrorType.ReCaptcha)
        return;
      }
      this.loading = true;
      let response: ContactFormResponse;
      if (this.isAuthenticated) {
        const contactFormQuery = {
          name: this.lastUserName,
          email: this.lastUserEmail,
          area: contactFormAreaToServerEnum(
            this.contactFormArea as ContactFormArea
          ),
          details: this.details,
          referrer: this.buttonInfo.url,
        } as ContactFormQuery;
        response = await privateContactFormService.handle(contactFormQuery);
      } else {
        const contactFormQuery = {
          name: this.lastUserName,
          email: this.lastUserEmail,
          area: contactFormAreaToServerEnum(
            this.contactFormArea as ContactFormArea
          ),
          details: this.details,
          reCaptchaResponseToken: this.reCaptchaResponse,
          referrer: this.buttonInfo.url,
        } as PublicContactFormQuery;
        response = await publicContactFormService.handle(contactFormQuery);
      }
      this.details = "";
      this.loading = false;
      this.reCaptchaIsSuccessful = false;
      this.contactFormArea = resources.messages.defaultAreaOptionDropdown;
      if (response.isSuccess) {
        this.$router.push("/contactForm/contactFormSent");
      }
    },
    reCaptchaSuccess() {
      this.reCaptchaIsSuccessful = true;
      errorsService.deleteByType(ErrorType.ReCaptcha);
    },
    reCaptchaExpired() {
      this.reCaptchaIsSuccessful = false;
    },
  },
  components: {
    ErrorsManager,
    RadialSpinner,
    Checkbox,
    LeftPanel,
    BlockableButton,
  },
  computed: {
    isDisabled() {
      return {
        "disabled-button": this.isAuthenticated
          ? !this.isValid
          : !(this.isValid && this.reCaptchaIsSuccessful),
      };
    },
    vProps() {
      return {
        variant: "outlined",
        density: "compact",
      };
    },
  },
});
